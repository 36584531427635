.response-details {
  font-size: 16px;
  padding: 10px 15px 30px 15px;
  word-break: break-all;
}

.response-details__section {
  display: flex;
  margin-bottom: 10px;
}

.response-details__control {
  max-width: 500px;
  border-collapse: collapse;
}

.response-details__control td {
  border: 2px solid #dddddd;
  padding: 2px 10px;
}

.response-details-control__name {

}

.response-details__info {
  border: 2px solid #dddddd;
  padding: 5px 10px;
  flex-grow: 1;
}

.response-details__info-block {
  margin-bottom: 10px;
}

.response-details__info-block:last-child {
  margin-bottom: 0;
}

.response-details__info-details {
  padding-left: 20px;
}

.response-details__control > span {
  border: 1px solid #dddddd;
  padding: 3px 8px;
  margin: 0;
}

.response-details__name {
  font-weight: 400;
  margin-right: 5px;
  width: 200px;
}

.response-details_print {
  display: none;
}

@media print {
  .response-details_print {
    display: block;
  }
}
