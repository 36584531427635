.insights-table__components {
  display: none;
}

.insights-table__components:last-child {
  display: block;
}

.insights-table__export-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;
}