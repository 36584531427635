.response-view__buttons {
  display: flex;
  justify-content: flex-end;
}

.response-view__noData {
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px auto;
}
