.pills-with-label {
  display: inline-flex;
  align-items: center;
  margin-bottom: 10px;
  margin-right: 30px;
}

.pills-with-label__label {
  display: inline-block;
  margin-right: 10px;
  font-size: 16px;
  text-align: right;
}
