.insights-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.insights-controls_disabled {
  opacity: 0.6;
  pointer-events: none;
}

.insights-controls__period {
  display: flex;
  justify-content: space-between;
}

.insights-controls__buttons {
  display: flex;
  gap: 10px;
  padding: 0 0 10px;
}

.insights-control__domain-name {
  margin: 3px 5px 0 5px;
}

div.insights-controls__name-search-input-form {
  padding-top: 1px;
  width: 400px;
}

@media only screen and (max-width: 1080px) {
  .insights-controls {
    flex-direction: column;
    align-items: flex-start;
  }
}
