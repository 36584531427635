.breadcrumbs__name {
  border: none;
  font: inherit;
  background: transparent;
  padding: 0;
  cursor: pointer;
  color: inherit;
  border-bottom: 1px solid;
  line-height: 18px;
}

.breadcrumbs__details {
  word-break: break-all;
}

.breadcrumbs__details:before {
  content: ' (';
}

.breadcrumbs__details:after {
  content: ')';
}

.breadcrumbs__separator {
  margin: 0 10px;
}
