.insights-layout {
  display: flex; }

.insights-layout__content {
  padding: 10px 20px;
  overflow: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; }

.insights-layout__filter {
  width: 250px;
  transition: all 100ms linear; }

.insights-layout__filter_disabled {
  opacity: 0.6;
  pointer-events: none; }

.insights-layout__filter-actions {
  width: 100%;
  display: flex;
  justify-content: center; }

.insights-layout__filter_collapsed {
  width: 30px; }

.insights-layout-filter__header {
  width: 100%;
  display: flex;
  justify-content: space-between; }

.insights-layout-filter__title_collapsed {
  display: none; }

.insights-layout-filter__tooltip-popper {
  top: 100px !important; }

@media (max-width: 960px) {
  .insights-layout {
    flex-direction: column; }
  .insights-layout__filter {
    width: 100%; }
  .insights-layout-filter__title_collapsed {
    display: block; } }
