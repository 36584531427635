.client-profiles-table__cell {
  text-align: center; }

.client-profiles-table__stats_online {
  color: green; }

.client-profiles-table__stats_offline {
  color: red; }

tbody.client-profiles-table {
  overflow-x: hidden; }
